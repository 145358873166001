import clsx from "clsx";
export function MobileNavIcon({
  open
}: {
  open: boolean;
}) {
  return <svg aria-hidden="true" className="h-iconSize w-iconSize overflow-visible stroke-iconStroke" fill="none" strokeWidth={2} strokeLinecap="round" data-sentry-element="svg" data-sentry-component="MobileNavIcon" data-sentry-source-file="MobileNavIcon.tsx">
            <path d="M0 1H14M0 7H14M0 13H14" className={clsx('origin-center transition', open && 'scale-navOpen opacity-hidden')} data-sentry-element="path" data-sentry-source-file="MobileNavIcon.tsx" />
            <path d="M2 2L12 12M12 2L2 12" className={clsx('origin-center transition', !open && 'scale-navOpen opacity-hidden')} data-sentry-element="path" data-sentry-source-file="MobileNavIcon.tsx" />
        </svg>;
}