'use client';

import { MouseEventHandler, useState, useTransition } from 'react';
import { Dialog, DialogPanel, Disclosure, DisclosureButton, DisclosurePanel, Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/react';
import { ArchiveBoxIcon, Bars3Icon, XMarkIcon, ChatBubbleOvalLeftEllipsisIcon, SquaresPlusIcon } from '@heroicons/react/24/outline';
// import { useUser } from '@clerk/nextjs';
import { ChevronDownIcon, PhoneIcon, PlayCircleIcon } from '@heroicons/react/20/solid';
// import { RocketIcon } from '@/images/icons';
import { Logo } from './Logo';
// import { Button } from '@/components/Button';
// import { useLocale, useTranslations } from 'next-intl';
import { useRouter } from 'next/navigation';
const features = [{
  name: 'Manage your documents',
  description: 'Organize and analyze your documents in one place',
  href: '#assistant',
  icon: ArchiveBoxIcon
}, {
  name: 'Embed on your website',
  description: 'Engage visitors and convert them to customers.',
  href: '#chatbot',
  icon: ChatBubbleOvalLeftEllipsisIcon
}, {
  name: 'Integrations',
  description: 'Integrate with your existing tools.',
  href: '#additional-features',
  icon: SquaresPlusIcon
}, {
  name: 'Customer feedback',
  description: 'Visitors can rate responses, enabling the chatbot to learn and adapt.',
  href: '#additional-features',
  icon: ChatBubbleOvalLeftEllipsisIcon
}];
const callsToAction = [{
  name: 'Watch demo',
  href: '#',
  icon: PlayCircleIcon
}, {
  name: 'Contact sales',
  href: '#',
  icon: PhoneIcon
}];
export default function HeaderWithSubmenu() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  // const { isSignedIn } = useUser();
  const {
    push
  } = useRouter();
  // const locale = useLocale();
  // const t = useTranslations('Navbar');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isPending, startTransition] = useTransition();
  const hideMobileMenuAndRedirect: MouseEventHandler<HTMLAnchorElement> = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  event) => {
    event.preventDefault();
    const path = window.location.hash;
    const href = event.currentTarget.href;
    if (path && path.includes('#')) {
      const id = path.replace('#', '');
      const element = window.document.getElementById(id);
      if (element) {
        const bound = element.getBoundingClientRect();
        setMobileMenuOpen(false);
        window.scrollTo({
          top: bound.top,
          behavior: 'smooth'
        });
      }
    }
    startTransition(() => {
      if (href.startsWith(window.location.origin)) {
        push(href);
      } else {
        window.location.href = href;
      }
      setMobileMenuOpen(false);
    });
  };
  return <header className="absolute inset-x-0 top-0 z-50 min-w-[404px]" data-sentry-component="HeaderWithSubmenu" data-sentry-source-file="HeaderWithSubmenu.tsx">
      <nav aria-label="Global" className="mx-auto flex max-w-7xl items-center justify-between p-6 lg:px-8">
        <div className="flex lg:flex-1">
          <a href="" className="-m-1.5 p-1.5">
            <span className="sr-only">Your Company</span>
            <Logo data-sentry-element="Logo" data-sentry-source-file="HeaderWithSubmenu.tsx" />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button type="button" onClick={() => setMobileMenuOpen(true)} className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700">
            <span className="sr-only">Open main menu</span>
            <Bars3Icon aria-hidden="true" className="size-6" data-sentry-element="Bars3Icon" data-sentry-source-file="HeaderWithSubmenu.tsx" />
          </button>
        </div>
        <PopoverGroup className="hidden lg:flex lg:gap-x-12 mt-1" data-sentry-element="PopoverGroup" data-sentry-source-file="HeaderWithSubmenu.tsx">
          <a href="#assistant" className="text-md font-semibold text-gray-900">
            AI Assistant
          </a>

          <a href="#chatbot" className="text-md font-semibold text-gray-900">
            Chatbot
          </a>

          <a href="#agents" className="text-md font-semibold text-gray-900">
            Agents
          </a>

          {/* <a href="#" className="text-sm/6 font-semibold text-gray-900">
            Marketplace
           </a> */}

          <Popover className="relative" data-sentry-element="Popover" data-sentry-source-file="HeaderWithSubmenu.tsx">
            {({
            open,
            close
          }) => <>
                <PopoverButton className="flex items-center gap-x-1 text-md font-semibold text-gray-900">
                  Features
                  <ChevronDownIcon aria-hidden="true" className="size-5 flex-none text-gray-400" />
                </PopoverButton>

                {open && <div className="fixed inset-0 z-10" onClick={() => close()} />}

                <PopoverPanel transition className="absolute left-1/2 top-full z-20 mt-3 w-screen max-w-md -translate-x-1/2 transform overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in">
                  <div className="p-4">
                    {features.map(item => <div key={item.name} className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm/6 hover:bg-gray-50">
                        <div className="flex size-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                          <item.icon aria-hidden="true" className="size-6 text-gray-600 group-hover:text-indigo-600" />
                        </div>
                        <div className="flex-auto">
                          <a href={item.href} onClick={() => close()} className="block font-semibold text-gray-900">
                            {item.name}
                            <span className="absolute inset-0" />
                          </a>
                          <p className="mt-1 text-gray-600">
                            {item.description}
                          </p>
                        </div>
                      </div>)}
                  </div>
                </PopoverPanel>
              </>}
          </Popover>

          <a href="#contact" className="text-md font-semibold text-gray-900">
            Contact us
          </a>
        </PopoverGroup>
        {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end"></div> */}
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          {/* {isSignedIn ? (
            <Button href={`https://app.ragen.ai`} color="blue">
              <span className="hidden sm:inline">{t('go-to-app')}</span>
              <span className="inline sm:hidden">
                <RocketIcon className="h-4 w-4" />
              </span>
            </Button>
           ) : (
            <Button href={`/${locale}/register`} color="blue">
              <span>
                {t('get-started')}{' '}
                <span className="hidden lg:inline">{t('today')}</span>
              </span>
            </Button>
           )} */}

          {/* <a href="#" className="text-sm/6 font-semibold text-gray-900">
            Log in <span aria-hidden="true">&rarr;</span>
           </a> */}
        </div>
      </nav>
      <Dialog open={mobileMenuOpen} onClose={setMobileMenuOpen} className="lg:hidden" data-sentry-element="Dialog" data-sentry-source-file="HeaderWithSubmenu.tsx">
        <div className="fixed inset-0 z-10" />
        <DialogPanel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10" data-sentry-element="DialogPanel" data-sentry-source-file="HeaderWithSubmenu.tsx">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Your Company</span>
              <Logo data-sentry-element="Logo" data-sentry-source-file="HeaderWithSubmenu.tsx" />
            </a>
            <button type="button" onClick={() => setMobileMenuOpen(false)} className="-m-2.5 rounded-md p-2.5 text-gray-700">
              <span className="sr-only">Close menu</span>
              <XMarkIcon aria-hidden="true" className="size-6" data-sentry-element="XMarkIcon" data-sentry-source-file="HeaderWithSubmenu.tsx" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <a href="#assistant" onClick={hideMobileMenuAndRedirect} className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                  AI Assistant
                </a>
                <a href="#chatbot" onClick={hideMobileMenuAndRedirect} className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                  Chatbot
                </a>
                <Disclosure as="div" className="-mx-3" data-sentry-element="Disclosure" data-sentry-source-file="HeaderWithSubmenu.tsx">
                  <DisclosureButton className="group flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50" data-sentry-element="DisclosureButton" data-sentry-source-file="HeaderWithSubmenu.tsx">
                    Features
                    <ChevronDownIcon aria-hidden="true" className="size-5 flex-none group-data-[open]:rotate-180" data-sentry-element="ChevronDownIcon" data-sentry-source-file="HeaderWithSubmenu.tsx" />
                  </DisclosureButton>
                  <DisclosurePanel className="mt-2 space-y-2" data-sentry-element="DisclosurePanel" data-sentry-source-file="HeaderWithSubmenu.tsx">
                    {[...features, ...callsToAction].map(item => <DisclosureButton key={item.name} as="a" onClick={hideMobileMenuAndRedirect} href={item.href} className="block rounded-lg py-2 pl-6 pr-3 text-sm/7 font-semibold text-gray-900 hover:bg-gray-50">
                        {item.name}
                      </DisclosureButton>)}
                  </DisclosurePanel>
                </Disclosure>

                <a href="#contact" onClick={hideMobileMenuAndRedirect} className="-mx-3 block rounded-lg px-3 py-2 text-base/7 font-semibold text-gray-900 hover:bg-gray-50">
                  Contact
                </a>
              </div>
              {/* <div className="py-6">
                <a
                  href="#"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base/7 font-semibold text-gray-900 hover:bg-gray-50"
                >
                  Log in
                </a>
               </div> */}
            </div>
          </div>
        </DialogPanel>
      </Dialog>
    </header>;
}