import { useId } from "react";
export function ReportingIcon() {
  const id = useId();
  return <>
            <defs data-sentry-element="defs" data-sentry-source-file="ReportingIcon.tsx">
                <linearGradient id={id} x1="11.5" y1={18} x2={36} y2="15.5" gradientUnits="userSpaceOnUse" data-sentry-element="linearGradient" data-sentry-source-file="ReportingIcon.tsx">
                    <stop offset=".194" stopColor="#fff" data-sentry-element="stop" data-sentry-source-file="ReportingIcon.tsx" />
                    <stop offset={1} stopColor="#6692F1" data-sentry-element="stop" data-sentry-source-file="ReportingIcon.tsx" />
                </linearGradient>
            </defs>
            <path d="m30 15-4 5-4-11-4 18-4-11-4 7-4-5" stroke={`url(#${id})`} strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" data-sentry-element="path" data-sentry-source-file="ReportingIcon.tsx" />
        </>;
}