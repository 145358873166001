'use client';

import { useEffect } from 'react';
import hljs from 'highlight.js';
import 'highlight.js/styles/base16/solarized-dark.css';
type Props = {
  htmlContent: string;
};
export const ContentCode = ({
  htmlContent
}: Props) => {
  useEffect(() => {
    hljs.highlightAll();
  }, [htmlContent]);
  return <pre data-sentry-component="ContentCode" data-sentry-source-file="ContentCode.tsx">
      <code dangerouslySetInnerHTML={{
      __html: htmlContent
    }} />
    </pre>;
};