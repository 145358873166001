'use client';

import { useEffect, useState } from 'react';
import Typewriter from 'typewriter-effect';
type Props = {
  texts: string[];
};
export const DynamicTypewriter = ({
  texts
}: Props) => {
  const [isClient, setIsClient] = useState(false);
  useEffect(() => {
    setIsClient(true);
  }, []);
  if (!isClient) return null;
  return <Typewriter options={{
    loop: true,
    delay: 75,
    deleteSpeed: 50
  }} onInit={typewriter => {
    texts.forEach(text => {
      typewriter.typeString(text).pauseFor(1000).deleteAll();
    });
    typewriter.start();
  }} data-sentry-element="Typewriter" data-sentry-component="DynamicTypewriter" data-sentry-source-file="DynamicTypewriter.tsx" />;
};