import Link from 'next/link';
export function NavLink({
  href,
  children
}: {
  href: string;
  children: React.ReactNode;
}) {
  return <Link href={href} className="inline-block rounded-lg px-2 py-1 font-semibold text-sm text-slate-700 hover:bg-slate-100 hover:text-slate-900" data-sentry-element="Link" data-sentry-component="NavLink" data-sentry-source-file="NavLink.tsx">
      {children}
    </Link>;
}